@import "./section.scss";
@import "./components.scss";
@import "./tql.scss";
@import "../fonts/index.scss";

:root {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --dark: #1f2725;
  --bckg: #eef2f0;
  --text-black: #1D2939;
  --divider: #2c3633;
  --divider-light: #E4E7EC;
  --text-light: #d4dcd8;
  --text-darker: var(--text-darker);
  --orange: #f16022;
  --gray: #667085;
  --green: #37A448;
  --main-bckg: #191F2D;
  --icon-bckg: #ffffff26;
  --mobile-text: #344054;
  scroll-behavior: smooth;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

h1 {
  font: 500 56px Archia;
  line-height: 60px;
}

h2 {
  color: #101828;
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  font-family: Archia;
  letter-spacing: -0.01em;
  margin: 5px auto;
}

h3 {
  font: 600 26px Archia;
}

h4 {
  font: 500 20px Inter;
}

h5 {
  font: 700 16px Inter;
}

p {
  color: var(--text-darker);
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  font-family: Manrope, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  align-self: stretch;
  margin: 10px auto;
}

body {
  box-sizing: border-box;
  font-family: Manrope, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  margin: 0;
}


.m {
  &-auto {
    &-40 {
      margin: auto 40px;
    }
  }

  &-top {
    &-auto {
      margin: 3vw auto 1vw auto;
    }
  }

  &-zero {
    margin: 0;
  }
}

.w {
  &-50 {
    width: 50%;

    &-mobile {
      width: 50%;

    }
  }

  &-40 {
    width: 40%;
  }

  &-30 {
    width: 30%;
  }
}

.f {
  &-18 {
    font-size: 18px;
  }
}

.pad {
  &-bot {
    &-30 {
      padding-bottom: 30px;
    }

    &-40 {
      padding-bottom: 40px;
    }

    &-10 {
      padding-bottom: 10px;
    }
  }

  &-73-width {
    padding: 0 73px;
  }


}

.text-align {
  text-align: center;
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 32px;
    margin: 10px 0;
    line-height: 42px;
    font-weight: 600;
  }

  h2 {
    font-size: 32px;
    text-align: start;
    font-weight: 600;
    line-height: 36px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
    margin: 5px 0;
    line-height: 26px;
  }

  p {
    margin: 2vw 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .w {
    &-50 {
      &-mobile {
        width: 100%;
      }
    }

    &-40 {
      &-mobile {
        width: 100%;
      }
    }
  }
}