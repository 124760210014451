.section {
  padding: 5vw 10vw;

  &-main {
    &-cut {
      height: 95vh;
      overflow: hidden;
      position: absolute;
      width: 100%;
    }

    &-img {
      object-fit: cover;
      z-index: -1;
      position: relative;
      width: 100%;
    }
  }

  &-register {
    background: var(--main-bckg);
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: white;
    }
  }



  &-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
  }

  &-steps {
    padding: 10vw 5vw;
  }

  &-gallery {
    padding: 1vw 9vw 9vw 9vw;
    background: var(--bckg);
  }

  &-banner {
    height: 450px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 2000px) {
  .section {
    padding: 2vw 20vw;
  }
}

@media screen and (max-width: 1000px) {
  .section {
    padding: 5vw;

    &-main {
      &-cut {
        height: 120vh;
      }

      &-img {
        width: auto;
        height: 100%;
      }
    }

    &-register {
      flex-direction: column-reverse;
    }

    &-center {
      padding: 2vw 5vw;

      img:first-child {
        width: 100%;
      }
    }


    &-banner {
      height: 190px;
    }


  }




}