@font-face {
  font-family: "archia";
  src: url("archia-thin-webfont.eot");
  src: url("archia-thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-thin-webfont.woff2") format("woff2"), url("archia-thin-webfont.woff") format("woff"),
    url("archia-thin-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "archia";
  src: url("archia-light-webfont.eot");
  src: url("archia-light-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-light-webfont.woff2") format("woff2"), url("archia-light-webfont.woff") format("woff"),
    url("archia-light-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "archia";
  src: url("archia-regular-webfont.eot");
  src: url("archia-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-regular-webfont.woff2") format("woff2"), url("archia-regular-webfont.woff") format("woff"),
    url("archia-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "archia";
  src: url("archia-medium-webfont.eot");
  src: url("archia-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-medium-webfont.woff2") format("woff2"), url("archia-medium-webfont.woff") format("woff"),
    url("archia-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "archia";
  src: url("archia-semibold-webfont.eot");
  src: url("archia-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-semibold-webfont.woff2") format("woff2"), url("archia-semibold-webfont.woff") format("woff"),
    url("archia-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "archia";
  src: url("archia-bold-webfont.eot");
  src: url("archia-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("archia-bold-webfont.woff2") format("woff2"), url("archia-bold-webfont.woff") format("woff"),
    url("archia-bold-webfont.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
