.image2 {
  width: 100%;
  height: 576px;
  border-radius: 8px;
  background-image: url('../images/tql/image2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title3 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.green-header {
  color: #37a448;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  letter-spacing: 0.08em;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
  margin-bottom: 5px;
}


.theTopQualityLivestockFamily {
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}

.text-header {
  color: #101828;
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  font-family: Archia;
  letter-spacing: -0.01em;
}

.text-normal {
  margin-top: 0;
  color: #101828;
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  font-family: Archia;
  letter-spacing: -0.01em;
}



.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 88px;
  bottom: 4026px;
  background-color: #191f2d;
}

.steps {
  width: 877px;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 24px;
}


@media screen and (max-width: 1000px) {
  .green-header {
    white-space: initial;
    width: 100%;
  }

  .image2 {
    width: 100%;
    height: 342px;
  }

  .title3 {
    margin-top: 30px;
    text-align: start;

    h2 {
      text-align: start;
    }
  }

  .medium {
    &:first-child {
      font: 500 18px Manrope;
    }
  }
}