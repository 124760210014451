.flex {
  display: flex;

  &-evenly {
    justify-content: space-evenly;
  }

  &-between {
    justify-content: space-between;
  }

  &-mobile {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-column {
    flex-direction: column;
  }

  &-top {
    align-self: flex-start;
  }

  &-bottom {
    align-self: flex-end;
  }

  &-start {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-gallery {
    gap: 8px;
    margin-top: 40px;

    img {
      width: 16vw;
    }
  }
}

.align-items {
  align-items: center;
}

header {
  font: 500 16px Manrope;
  padding: 1vw 5vw;

  a {
    margin-right: 40px;
  }

  button:last-child {
    margin-right: 5vw;
  }
}

.header {
  &-about {
    margin-left: 2vw;
  }
}

.feature {
  display: flex;
  align-items: center;
  gap: 60px;
  justify-content: space-between;

  &-text {
    width: 40%;

    h3 {
      margin: 0;
    }

    p {
      text-align: justify;
      color: var(--text-darker);
      font: 500 18px Manrope;
      line-height: 28px;
    }
  }

  &-image {
    width: 40%;

    img {
      width: 80%;
    }
  }
}

.main {
  background: var(--main-bckg);
  margin: 0;
  min-height: 40vh;
  color: white;
  padding: 5vw 10vw;

  h1 {
    margin: 24px auto;
  }

  h1,
  h3 {
    text-align: center;
  }

  &-icons {
    padding: 0 10vw;
  }

  &-getoffers {
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: block;
    margin: 40px auto;
  }

  button {
    margin: 1vw auto;
    width: 326px;
    height: 60px;
    text-align: center;
    align-items: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
}

.iconText {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 4px 16px;
  border-radius: 40px;
  background-color: var(--icon-bckg);
}

.list {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    padding: 1.3vw 0;

    &:not(:last-child) {
      border-bottom: 3px dashed rgba(0, 0, 0, 0.08);
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  &-white {
    color: white;
    font-size: 18px !important;
  }

  &-text {
    font: 500 16px Inter;
    margin-left: 18px;
    width: 25vw;
  }
}

.slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  z-index: 10;
  position: relative;

  &-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 2px;
    z-index: 5;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    color: white;
    font: 700 14px Manrope;
  }
}

.slides {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;

  &:hover {
    .arrow {
      opacity: 1;
    }
  }
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.active {
  opacity: 1;
}

.slide img,
video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  width: 25%;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &-hover {
    &-text {
      width: 100%;
      z-index: 1;
      height: 100%;
      background: rgba(0, 0, 0, 0.65);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 8px;
      font-size: 19px;
    }
  }

  header {
    color: #101828;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    font-family: Manrope;
    padding: 0;
    margin-top: 10px;
  }

  p {
    margin: 0;
  }

  &-description {
    color: var(--text-darker);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 10px;
  }

  &-location {
    color: var(--text-darker);
    line-height: 20px;
    font: 500 14px Manrope;
    margin-top: 15px;

    img {
      width: 1.3vw;
    }
  }

  &-price p {
    color: #0d0d0d;
    margin-top: 5px;
    font: 600 20px Manrope;
    line-height: 32px;
  }
}

.watched {
  width: min-content;
  height: min-content;
  align-items: center;
  gap: 4px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
  padding: 4px 9px;
  outline: solid 1px #e4e7ec;
  outline-offset: -1px;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;

  img {
    width: 20px;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border: none;
  background: rgba(255, 255, 255);
  border-radius: 8px;
  color: #333;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 2;
}

.clean {
  &-anchor {
    text-decoration: none;
    color: #101828;
  }
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.highlight {
  &-strong {
    font-style: italic;
    color: var(--text-black);

    &-bold {
      font-style: italic;
      color: var(--text-black);
      font-weight: 600;
    }
  }

  &-orange {
    font-style: italic;
    color: var(--orange);
  }

  &-green {
    font-style: italic;
    color: var(--green);
  }

  &-gen {
    background: var(--green);
    color: white;
    border-radius: 4px;
    padding: 0 5px;
  }
}

.d8 {
  padding: 22px;
  text-align: center;
}

.divider {
  &-dash {
    border-bottom: 1px dashed var(--divider);
    width: 100%;
    height: 1px;
  }

  &-light {
    border-bottom: 1px solid var(--divider-light);
    width: 42%;
    height: 1px;
  }

  &-side {
    border-right: 1px solid var(--text-light);
    width: 1px;
    height: auto;
  }
}

form {
  button {
    height: 50px;
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    font-size: 18px !important;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  width: 45vw;
  gap: 24px;

  &-input {
    width: 100%;
    max-width: 300px;
  }

  input {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid var(--divider-light);
    height: 69px;
    width: 100%; // width: 300px;
  }
}

.footer {
  padding: 5vw 10vw;

  span {
    font: 600 18px Manrope;
    line-height: 28px;
    color: #7d6d5d;
  }

  &-details {
    margin-top: 10px;

    p {
      font: 600 24px Archia;
      line-height: 36px;
      margin: 0;
    }
  }

  &-copyright {
    border-top: 1px solid var(--text-light);
    margin-top: 40px;
    padding-top: 30px;

    p {
      font: 500 14px Manrope;
      color: var(--text-darker);
      margin: 0;
    }
  }
}

.gap {
  gap: 10px;

  &-2vw {
    gap: 2vw;
  }

  &-5vw {
    gap: 5vw;
  }

  &-24 {
    gap: 24px;
  }
}

.column-photos {
  max-width: 529px;

  img {
    width: 100%;
  }
}

table {
  width: 475px;
  padding: 20px;
  background: var(--bckg);
  border-collapse: separate;
  border-spacing: 10px 15px;
  line-height: 22px;
  font: 500 14px Inter;

  td:nth-child(2) {
    width: 51%;
  }

  td.light {
    color: var(--text-darker);
  }
}

.success {
  color: green;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.error {
  color: red;
}

@mixin button {
  border: none;
  border-radius: 4px;
  font: 500 14px Inter;
  color: white;
  background: var(--orange);
  transition: 0.2s;
  cursor: pointer;
  z-index: 5;

  &:hover {
    transition: 0.2s;
    transform: scale(1.02);
  }
}

.no-decor {
  color: var(--text-black);
  text-decoration: none;
}

.button {
  @include button;

  &-action {
    display: flex;
    margin-left: 0;

    a {
      text-decoration: none;
      color: white;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    margin: 0;
    padding: 15px 20px;
    display: inline-block;
    width: 100%;
  }

  &-transparent {
    background: white;
    color: var(--text-black);
    border: 1px solid var(--divider-light);
  }

  &-login {
    background: var(--green);
  }
}

.history {
  height: 15vw;
  border-radius: 8px;
  overflow: hidden;

  &-first {
    height: 100%;
    width: 48%;
    object-fit: cover;
  }

  &-second {
    height: 100%;
    width: 100%;

    img {
      height: 49%;
      object-fit: cover;
    }
  }
}

.start-selling {
  background-image: url("../images/tql/tqlbck.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  min-height: 797px;
}

.sc {
  // padding: 23px;
  border-radius: 10px;
  background: white;
  width: 40%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 28px;
      font-family: Archia;
      margin: 5px auto;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }

  &-price {
    &-header {
      color: #7d6d5d;
      font: 600 16px Manrope;
    }

    &-for-cow {
      display: block;
      margin-top: 5px;
      color: #101828;
      font: 800 24px Manrope;
    }
  }

  &-footer {
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-icons {
      height: 19px;
      align-items: center;
      gap: 6px;
      padding: 2px 0;
      border-radius: 6px;
      display: flex;
      font: 500 16px Manrope;
    }
  }
}

.greenLine {
  flex-grow: 1;
  background-image: url("../images/tql/greenLine.svg");
  object-fit: fill;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 6vw 0 10vw 0;
  width: 5%;
}

.ourHistoryImage {
  width: 100%;
  max-width: 534px;
  height: 576px;
  object-fit: cover;
  border-radius: 8px;
}

@media screen and (min-width: 2000px) {
  .footer {
    padding: 2vw 20vw;
  }

  .form {
    width: 32vw;
  }

  .sc {
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {

  .sc {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .sc {
    display: none;
  }


  header {
    .button {

      a {
        padding: 8px 12px;
        white-space: nowrap;
      }
    }
  }

  .greenLine {
    flex-grow: 1;
    object-fit: fill;
    width: 35%;
    margin: 6vh 0 21vh 0;
    background-image: url("../images/tql/greenLineMobile.svg");
  }

  button {
    max-width: 100vw;
    width: 100%;
    text-align: center;
    justify-content: center;
  }



  .footer {
    padding: 2vw 5vw;

    &-rights {
      margin-top: 20px !important;
    }
  }

  header {
    img {
      width: 60px;
    }

    button {
      max-width: 100% !important;
      // padding: 10px !important;

      &:last-child {
        margin: 0;
      }
    }
  }

  .feature {
    margin-top: 50px;

    &-text {
      width: 100%;

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &-image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }




  .main {
    padding: 5vw;

    &-icons {
      gap: 10px;
      flex-wrap: wrap;
      padding: 2vw 0;
    }

    button {
      width: 100%;
      max-width: 100%;
      margin-top: 5vw;
      margin-bottom: 140px;
    }
  }

  .iconText {
    padding: 8px 16px;

    span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .card {
    width: 80vw;
    overflow: initial;

    &-location {
      img {
        width: 5vw !important;
      }
    }
  }

  .slider {
    max-width: initial;
    width: 80vw;

    .slide {
      img {
        width: 100%;
      }
    }
  }

  .watched {
    width: 15%;
  }

  .mobile {
    &-hidden {
      display: none;
    }
  }

  .form {
    width: 100%;

    &-input {
      width: 100%;
      max-width: 100vw;
    }

    input {
      width: 100%;
    }
  }

  form {
    button {
      width: 100%;
    }
  }

  .flex {
    &-mobile {
      flex-direction: column;

      &-reverse {
        flex-direction: column-reverse;
      }
    }

    &-top {
      align-self: flex-start;
    }

    &-bottom {
      align-self: flex-start;
    }

    &-gallery {
      overflow-x: scroll;
      margin-top: 10px;

      img {
        width: 35%;
      }
    }
  }

  .table {
    &-bid {
      width: 80vw;
      border-spacing: 10px;
    }
  }

  .img {
    &-100 {
      width: 100%;
    }

    &-h {
      &-90 {
        height: 90%;
      }
    }
  }

  .start-selling {
    padding: 10vw 5vw;
    height: auto;
    min-height: initial;
  }

  #about_us {
    p {
      color: var(--mobile-text);
      line-height: 32px;
      font-weight: 400;
    }
  }

  .list {
    li {
      padding: 20px 0;
    }

    &-text {
      font-size: 16px;
      white-space: break-spaces;
      display: block;
      max-width: 80%;
      width: 80%;
    }
  }

  table {
    width: auto;
    font-size: 12px;
    padding: 10px;
    border-spacing: 10px;

    td:nth-child(2) {
      width: auto;
    }
  }

  .divider {
    &-side {
      border-bottom: 1px solid var(--text-light);
      width: 80%;
      height: 1px;
      margin: 25px 0 25px auto;
    }
  }
}